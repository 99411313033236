.adyen-checkout__payment-method {
  background-color: #050505;
}
.adyen-checkout__payment-method--selected {
}
.adyen-checkout__payment-method__header {
  /* Payment method icon and name */
}
.adyen-checkout__payment-method__radio {
  /* Radio button in payment method header */
}
.adyen-checkout__payment-method__radio--selected {
  background-color: #0064cb;
  /* Selected radio button in payment method header */
}
.adyen-checkout__payment-method__name {
  color: #f5f5f5;
  /* Payment method name in the payment method header */
}
.adyen-checkout__spinner__wrapper {
  /* Spinning icon */
}
.adyen-checkout__button {
  background-color: black;
}
.adyen-checkout__button--pay {
  height: 44px;
  font-weight: 600;
  font-size: '1rem';
  border-radius: '0.5rem';
  background-color: #008fff;
}
.adyen-checkout__button--pay:hover {
  border-color: white;
  background-color: #008fff;
}
.adyen-checkout__field {
  color: #0064cb;
  /* Form field container */
}
.adyen-checkout__label {
  color: #0064cb;
  /* Form label container */
}
.adyen-checkout__label__text {
  color: #0064cb;
  /* Text element inside the form label container */
}

.adyen-checkout__input {
  color: #0064cb;
  /* Input fields */
}
.adyen-checkout__input::placeholder {
  color: #0064cb;
  /* Input fields */
}
.adyen-checkout__input--error {
  /* Error state for the input fields */
}
.adyen-checkout__error-text {
  /* Error message text */
}
.adyen-checkout__card__cardNumber__input {
  color: #0064cb;
  /* Input field for the card number */
}
.adyen-checkout__card__cardNumber__input::placeholder {
  color: #0064cb;
  /* Input field for the card number */
}
.adyen-checkout__field--expiryDate {
  color: #0064cb;
  /* Input field for the expiry date */
}
.adyen-checkout__field__cvc {
  color: #0064cb;
  /* Input field for the CVC security code */
}
.adyen-checkout__card__holderName {
  /* Input field for cardholder name */
}
.adyen-checkout__checkbox__input {
  /* Checkboxes */
}
.adyen-checkout__checkbox__label {
  /* Checkbox labels */
}
.adyen-checkout__radio_group__input {
  /* Radio buttons */
}
.adyen-checkout__dropdown__button {
  /* Dropdown button showing list of options */
}
.adyen-checkout__dropdown__list {
  /* Dropdown list */
}
.adyen-checkout__dropdown__element {
  /* Elements in the dropdown list */
}
.adyen-checkout__link {
  /* Links */
}
